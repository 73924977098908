import React, { useEffect, useState } from "react";
import axios from "axios";
import Card from "./Card";
import "./hero.css";

const Hero = ({news}) => {
  // const [news, setNews] = useState([]);
  // const baseUrl = "https://api-sites-en.vercel.appadmin";
  const sortedNews = news.slice(-4).sort((a, b) => b.id - a.id);
  // const [preloadLink, setPreloadItem] = useState(null)
  // const getNews = async () => {
  //   try {
  //     const res = await axios.get(`${baseUrl}/news`);
  //     setNews(res.data);
  //     const ultimaNoticia = res.data[res.data.length - 1].cover
  //     setPreloadItem(ultimaNoticia)
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  useEffect(()=>{
        if(news.length){
          const link = document.createElement("link");
          link.rel = "preload";
          link.href = news[news.length - 1].cover;
          link.as = "image";
          if(!document.querySelector("head").contains(link)){
            document.querySelector("head").append(link);
          }
        }
  }, [news])

  // useEffect(() => {
  //   getNews();
  // }, []);

  return (
    <>
      <section className="hero">
        <div className="container">
          {sortedNews.map((item) => {
            return <Card key={item.id} item={item} />;
          })}
        </div>
      </section>
    </>
  );
};

export default Hero;
