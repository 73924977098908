import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Hero from "./hero/Hero";
import Home from "./mainContent/Home";
import axios from "axios";
const Homepage = () => {
  const [news, setNews] = useState([])
  useEffect(()=>{
    async function getNews(){
      try{
      const res = await axios.get("https://api-sites-en.vercel.app/news")
      setNews(res.data)
    }
    catch(err){
      console.log(err)
    }
    }
    getNews()
  },[])
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>HOME | EN PARÁ</title>
        </Helmet>
      </HelmetProvider>

      <Hero news={news} />
      <Home news={news} />
    </>
  );
};

export default Homepage;
