import React from 'react'
import "./loader.css"
const Loader = () => {
  return (
    <div className="loader-container">
      <span className="loader"></span>
    </div>
  );
}

export default Loader